import ImageOne from '../images/rockInRio.jpeg';
import ImageTwo from '../images/rockInRio3.jpeg';
import ImageThree from '../images/slideSM3.JPG';

export const SliderData = [
    {
        title: 'Rock in Rio Session',
        path: '/rockinrio',
        label: 'Ver mais',
        image: ImageOne,
        alt: 'ROCK IN RIO SESSION'
    },
    /*{
        title: 'Bruno Mozi',
        path: '/sambaDoMozi',
        label: 'Ver mais',
        image: ImageTwo,
        alt: 'BAILE DO MOZI'
    },
    {
        title: 'Samba do Mozi',
        path: '/casadeshow',
        label: 'Ver mais',
        image: ImageThree,
        alt: 'Samba do Mozi'
    },*/

]