import Image0 from '../images/story1.mov';
import Image19 from '../images/storyArtista1.mp4';
import Image20 from '../images/storyArtista2.mp4';
import Image3 from '../images/storyArtista3.mp4';
import Image4 from '../images/storyArtista4.mp4';
import Image12 from '../images/storyArtista5.mov';
import Image5 from '../images/storyFesta1.mov';
import Image6 from '../images/storyFesta2.mov';
import Image7 from '../images/storyFesta3.mp4';
import Image8 from '../images/storyFesta4.mp4';
import Image9 from '../images/storyFesta5.mp4';
import Image10 from '../images/storyFesta6.mov';
import Image11 from '../images/storyFesta7.mp4';
import Image17 from '../images/storyFestaL5.mp4';
import Image13 from '../images/storyFestaL6.mp4';
import Image14 from '../images/storyFestaL1.mp4';
import Image15 from '../images/storyFestaL2.mp4';
import Image16 from '../images/storyFestaL3.mp4';
import Image1 from '../images/soulBrado.MOV';
import Image2 from '../images/360Sport.MOV';
//import Image18 from '../images/videoCasamento.MOV';
import ImageIphoneScreenTwo from '../images/pngIphoneScreen.png';

export const StoryData = [
    
    
    
    {
    heading: 'Exeplore all the things that you can',
    image:  Image1,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image:  Image1,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image2,
    imageTwo:  ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image3,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image4,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image5,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    
    {
    heading: 'Exeplore all the things that you can',
    image: Image6,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    
    {
    heading: 'Exeplore all the things that you can',
    image: Image7,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image8,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image9,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image10,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image11,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image17,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image13,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image14,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image15,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   },
    {
    heading: 'Exeplore all the things that you can',
    image: Image16,
    imageTwo: ImageIphoneScreenTwo,
    reverse: false,
    delay: 100
   }
    
]