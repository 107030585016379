import React from 'react'
import styled, {css} from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { HashLink as Direcionador } from 'react-router-hash-link';
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import {FaBars} from 'react-icons/fa';
import instaIcom from '../images/instagramIcon.png'
import FacebookIcom from '../images/facebookIcon.png'
import TwitterIcom from '../images/twitterIcon.png'
import YouTubeIcom from '../images/youtubeIcon.png'

const Nav = styled.nav`
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    width: 100%;
    position: fixed;
`;

const ButtonInsta = styled(Link)`
    padding-right: 10px;
`

const NavLink = css`
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
`

const Logo = styled(Direcionador)`
    ${NavLink};
`

const MenuBars = styled(FaBars)`
    display: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        display: block;
        width: 50px;
        height: 25px;
        color: #fff;
    }
`

const NavMenu = styled.div`
    display: flex;
    align-itens: center;
    margin-right: -48px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

const NavMenuLinks = styled(Direcionador)`
    ${NavLink};
    color: #fff;
`

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Icon = styled.img`
cursor: pointer;
`

const Navbar = ({toggle}) => {

    function handleClickWpp() {
          window.location.href = "https://wa.me/5521982643867?text=Olá,%20vi%20seu%20trabalho%20através%20do%20seu%20website%20e%20gostaria%20de%20um%20orçamento :)"
    }
    function handleClickInsta() {
          window.location.href = "https://www.instagram.com/brunomozi/"
    }
    function handleClickFacebook() {
          window.location.href = "https://www.facebook.com/profile.php?id=100086632946992"
    }
    function handleClickTwitter() {
          window.location.href = "https://mobile.twitter.com/brunomozi"
    }
    function handleClickYouTube() {
          window.location.href = "https://www.youtube.com/@Bailedomozi"
    }
    return (
        <Nav>
            <Logo smooth to='/#home'>Bruno Mozi</Logo>
            <MenuBars onClick={toggle}/>
            <NavMenu>
                {menuData.map((item, index) => (
                    <NavMenuLinks smooth to={item.link} key={index}>
                        {item.title}
                    </NavMenuLinks>
                ))}
                 <ButtonInsta onClick={handleClickInsta} to=''><Icon src={instaIcom} alt="Icone do instagram"/></ButtonInsta>
                 <ButtonInsta onClick={handleClickFacebook} to=''><Icon src={FacebookIcom} alt="Icone do instagram"/></ButtonInsta>
                 <ButtonInsta onClick={handleClickYouTube} to=''><Icon src={YouTubeIcom} alt="Icone do instagram"/></ButtonInsta>
                 <ButtonInsta onClick={handleClickTwitter} to=''><Icon src={TwitterIcom} alt="Icone do instagram"/></ButtonInsta>
            </NavMenu>
            <NavBtn>
                <Button onClick={handleClickWpp} to="" primary="true" >Entre em contato</Button>
            </NavBtn>
        </Nav>
    );
};

export default Navbar
