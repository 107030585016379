import Video from '../images/RockInRioVideo.mp4'

export const RockInRioFirstVdeo = [
     {
        title: 'Rock in rio session',
        path: '../images/pagodeNordico1.mov',
        description: 'Algum texto aqui para ser adicionado, explicando sobre o evento e falando algumas coisas diferenciais!',
        video: Video,
        alt: 'Vído de samba'
    }
]
