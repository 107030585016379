import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import instaIcom from '../images/instagramIcon.png'
import { HashLink as Direcionador } from 'react-router-hash-link';
import FacebookIcom from '../images/facebookIcon.png'
import TwitterIcom from '../images/twitterIcon.png'
import YouTubeIcom from '../images/youtubeIcon.png'

const DropdownContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: black;
    display: grid;
    align-items: center;
    text-align: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`
const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`
const CloseIcon = styled(FaTimes)`
    color: #fff;
`
const DropdownWrapper = styled.div`

`
const DropdownMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 88px);
    text-align: center;
    margin-bottom: 4rem;
`
const DropdownLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #cd853f  ;
    }

    a{
        text-decoration: none;
        color: white;
    }
`
const BtnWrap = styled.div`

`
const IconInsta = styled.img`
cursor: pointer;
`


const Dropdown = ({ isOpen, toggle }) => {
    return (
        <DropdownContainer isOpen={isOpen} >
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <DropdownWrapper>
                <DropdownMenu>
                    {menuData.map((item, index) => (
                        <DropdownLink smooth to={item.link} key={index}>
                               <a href={item.link} >{item.title}</a> 
                        </DropdownLink>
                    ))}
                     <a href='https://www.instagram.com/brunomozi'><IconInsta src={instaIcom} alt="Icone do instagram"/></a>
                     <a href='https://www.facebook.com/profile.php?id=100086632946992'><IconInsta src={FacebookIcom} alt="Icone do Facebook"/></a>
                     <a href='https://www.youtube.com/@Bailedomozi'><IconInsta src={YouTubeIcom} alt="Icone do Facebook"/></a>
                     <a href='https://mobile.twitter.com/brunomozi'><IconInsta src={TwitterIcom} alt="Icone do Facebook"/></a>
                </DropdownMenu>
                <BtnWrap>
                <Button to="" primary="true" ><a href='https://api.whatsapp.com/send/?phone=5521964370776&text&app_absent=0'>Entre em contato</a></Button>
                </BtnWrap>
            </DropdownWrapper>
            <h1>Hi</h1>
        </DropdownContainer>
    )
}

export default Dropdown
