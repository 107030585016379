import Video from '../images/sambaDoMozi.mov';

export const SambaDoMoziFirstVideo = [
    {
        title: 'Samba do Mozi é sinônimo de casa cheia!',
        path: '../images/pagodeNordico1.mov',
        description: 'Algum texto aqui para ser adicionado, explicando sobre o evento e falando algumas coisas diferenciais!',
        video: Video,
        alt: 'Vído de samba'
    }

]