import ImageOne from '../images/slideRR1.jpeg';
import ImageTwo from '../images/slideRR2.jpeg';
import ImageThree from '../images/slideRR3.jpeg';
import ImageFour from '../images/slideRR4.jpeg';

export const SlideRockInRioData = [
    {
        title: 'Rock in Rio Session',
        path: '../images',
        label: 'View more',
        image: ImageOne,
        alt: 'Rock in Rio session'
    },
    {
        title: 'Casamento',
        path: '../images',
        label: 'View more',
        image: ImageTwo,
        alt: 'Rock in Rio Session'
    },
    {
        title: 'Festa Corporativa',
        path: '../images',
        label: 'View more',
        image: ImageThree,
        alt: 'Rock in Rio Session'
    },
    {
        title: 'Festa Corporativa',
        path: '../images',
        label: 'View more',
        image: ImageFour,
        alt: 'Rock in Rio Session'
    }

]